import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconPlayButton(props: IconProps) {
	return (
		<Icon viewBox="0 0 80 80" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M40 80a40 40 0 1 0 0-80 40 40 0 0 0 0 80Zm-6.3-25.3L56.3 42c1.7-1 1.7-3.3 0-4.2L33.8 25.3c-1.6-.9-3.7.3-3.7 2.1v25.2c0 1.8 2 3 3.8 2Z"
				fill="currentColor"
			/>
		</Icon>
	);
}
